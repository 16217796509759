import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const GroupPage = lazy(() =>
	import("./group/GroupPage")
);

const GroupEmbarcadorPage = lazy(() =>
	import("./group-embarcador/GroupPage")
);

const GroupTransportadorPage = lazy(() =>
	import("./group-transportador/GroupPage")
);

const AdminPage = lazy(() =>
	import("./admin/AdminPage")
);

const MotoristaPage = lazy(() =>
	import("./motorista/MotoristaPage")
);

const FretePage = lazy(() =>
	import("./frete/FretePage")
);

const FreteAgendamentoPage = lazy(() =>
	import("./agendamento-frete/FreteAgendamentoPage")
);

const RelatorioPage = lazy(() =>
	import("./relatorio/RelatorioPage")
);

const LeilaoPage = lazy(() =>
	import("./leilao/LeilaoPage")
);

const OrcamentoPage = lazy(() =>
	import("./orcamento/OrcamentoPage")
);

const OfertaPage = lazy(() =>
	import("./oferta/OfertaPage")
);

const EmbarcadoraPage = lazy(() =>
	import("./embarcadora/EmbarcadoraPage")
);

const TransportadoraPage = lazy(() =>
	import("./transportadora/TransportadoraPage")
);

const AvaliacaoPage = lazy(() =>
	import("./avaliacao/AvaliacaoPage")
);

const UsuarioPage = lazy(() =>
	import("./usuario/UsuarioPage")
);

const DashboardPatioPage = lazy(() =>
	import("./dashboard-patio/DashboardPatioPage")
);

const ConfirmacaoPixPage = lazy(() =>
	import("./pix/ConfirmacaoPixPage")
);

const PatioPage = lazy(() =>
	import("./patio/PatioPage")
);

const ServicoEstacionamentoPage = lazy(() =>
	import("./servico-estacionamento/ServicoEstacionamentoPage")
);

const ServicoLimpezaPage = lazy(() =>
	import("./servico-limpeza/ServicoLimpezaPage")
);

const ServicoClassificacaoProduto = lazy(() =>
	import("./servico-classificacao-produto/ServicoClassificacaoProdutoPage")
);

const AgendamentosPage = lazy(() =>
	import("./agendamento/AgendamentosPage")
);

const DescontoPage = lazy(() =>
	import("./desconto/DescontoPage")
);

const UsuarioPatioPage = lazy(() =>
	import("./usuario-patio/UsuarioPatioPage")
);

const RelatorioPatioPage = lazy(() =>
	import("./relatorio-patio/RelatorioPatioPage")
);

const VagaMensalPage = lazy(() =>
	import("./vaga-mensal/VagaMensalPage")
);


export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/group" component={GroupPage} />
				<Route path="/group-embarcador" component={GroupEmbarcadorPage} />
				<Route path="/group-transportador" component={GroupTransportadorPage} />
				<Route path="/admin" component={AdminPage} />
				<Route path="/motorista" component={MotoristaPage} />
				<Route path="/frete" component={FretePage} />
				<Route path="/lote" component={FreteAgendamentoPage} />
				<Route path="/relatorio" component={RelatorioPage} />
				<Route path="/leilao" component={LeilaoPage} />
				<Route path="/orcamento" component={OrcamentoPage} />
				<Route path="/oferta" component={OfertaPage} />
				<Route path="/embarcadora" component={EmbarcadoraPage} />
				<Route path="/transportadora" component={TransportadoraPage} />
				<Route path="/avaliacao" component={AvaliacaoPage} />
				<Route path="/usuario" component={UsuarioPage} />

				{/* patio */}
				<Route exact path="/dashboard-patio" component={DashboardPatioPage} />
				<Route path="/patio" component={PatioPage} />
				<Route path="/servico/estacionamento" component={ServicoEstacionamentoPage} />
				<Route path="/servico/limpeza" component={ServicoLimpezaPage} />
				<Route path="/servico/classificacao-produto" component={ServicoClassificacaoProduto} />
				<Route path="/desconto" component={DescontoPage} />
				<Route path="/agendamento" component={AgendamentosPage} />
				<Route path="/usuario-patio" component={UsuarioPatioPage} />
				<Route path="/relatorio-patio" component={RelatorioPatioPage} />
				<Route path="/pix" component={ConfirmacaoPixPage} />
				<Route path="/vaga-mensal" component={VagaMensalPage} />

				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense>
	);
}
