export default {
	header: {
		self: {},
		items: [
			{
				title: "Google Play",
				root: true,
				alignment: "left",
				link: "https://play.google.com/console/u/0/developers/8222657441807507564/app/4974318662411094913/app-dashboard?timespan=thirtyDays&showKpiMenu=null",
			},
			{
				title: "Apple Store",
				root: true,
				alignment: "left",
				link: "https://appstoreconnect.apple.com/analytics/app/d30/1539926395/overview",
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Motoristas",
				root: true,
				icon: "fas fa-truck",
				page: "motorista",
				bullet: "dot"
			},
			{
				title: "Fretes",
				root: true,
				icon: "fas fa-location-arrow",
				page: "frete",
				bullet: "dot"
			},
			{
				title: "Agendamento de Fretes",
				root: true,
				icon: "fas fa-clipboard-list",
				page: "lote",
				bullet: "dot"
			},
			{
				title: "Leilões",
				root: true,
				icon: "fas fa-comments-dollar",
				page: "leilao",
				bullet: "dot"
			},
			{
				title: "Orçamentos",
				root: true,
				icon: "fas fa-file-contract",
				page: "orcamento",
				bullet: "dot"
			},
			{
				title: "Ofertas",
				root: true,
				icon: "fas fa-tags",
				page: "oferta",
				bullet: "dot"
			},
			{
				title: "Embarcadoras",
				root: true,
				bullet: "dot",
				icon: "fas fa-store",
				submenu: [
					{
						title: "Ativas",
						bullet: "dot",
						page: "embarcadora/ativos"
					},
					{
						title: "Inativas",
						bullet: "dot",
						page: "embarcadora/inativos"
					},
					{
						title: "Pendentes",
						bullet: "dot",
						page: "embarcadora/pendentes"
					},
				]
			},
			{
				title: "Transportadoras",
				root: true,
				bullet: "dot",
				icon: "fas fa-truck-loading",
				submenu: [
					{
						title: "Ativas",
						bullet: "dot",
						page: "transportadora/ativos"
					},
					{
						title: "Inativas",
						bullet: "dot",
						page: "transportadora/inativos"
					},
					{
						title: "Pendentes",
						bullet: "dot",
						page: "transportadora/pendentes"
					},
				]
			},
			{
				title: "Avaliações",
				root: true,
				bullet: "dot",
				icon: "fas fa-star-half-alt",
				submenu: [
					{
						title: "Embarcadoras",
						bullet: "dot",
						page: "avaliacao/embarcadora"
					},
					{
						title: "Transportadoras",
						bullet: "dot",
						page: "avaliacao/transportadora"
					},
				]
			},
			{
				title: "Gestão de Pátios",
				root: true,
				bullet: "dot",
				icon: "fas fa-parking",
				submenu: [
					{
						title: "Dashboard",
						bullet: "dot",
						page: "dashboard-patio"
					},
					{
						title: "Confirmação via PIX",
						bullet: "dot",
						page: "pix"
					},
					{
						title: "Pátios",
						bullet: "dot",
						page: "patio"
					},
					{
						title: "Serviços",
						bullet: "dot",
						page: "servico",
						submenu: [
							{
								title: "Limpeza",
								bullet: "dot",
								page: "servico/limpeza"
							},
							{
								title: "Estacionamento",
								bullet: "dot",
								page: "servico/estacionamento"
							},
							{
								title: "Classificação de Produto",
								bullet: "dot",
								page: "servico/classificacao-produto"
							},
						]
					},
					{
						title: "Desconto",
						bullet: "dot",
						page: "desconto"
					},
					{
						title: "Agendamentos",
						bullet: "dot",
						submenu: [
							{
								title: "Limpeza",
								bullet: "dot",
								page: "agendamento/limpeza"
							},
							{
								title: "Estacionamento",
								bullet: "dot",
								page: "agendamento/estacionamento"
							},
							{
								title: "Classificação de Produto",
								bullet: "dot",
								page: "agendamento/classificacao-produto"
							},
						]
					},
					{
						title: "Vagas Mensais",
						bullet: "dot",
						page: "vaga-mensal"
					},
					{
						title: "Relatórios",
						bullet: "dot",
						submenu: [
							{
								title: "Financeiro",
								bullet: "dot",
								page: "relatorio-patio/relatorio01"
							},
							{
								title: "Cobrança",
								bullet: "dot",
								page: "relatorio-patio/relatorio02"
							},
						]
					},
				]
			},
			{
				title: "Relatórios",
				root: true,
				bullet: "dot",
				icon: "fas fa-chart-pie",
				submenu: [
					{
						title: "Cadastros Não Finalizados",
						bullet: "dot",
						page: "relatorio/relatorio01"
					},
					{
						title: "Carga x Transportadora",
						bullet: "dot",
						page: "relatorio/relatorio02"
					},
					{
						title: "Cargas x Região",
						bullet: "dot",
						page: "relatorio/relatorio03"
					},
					{
						title: "Produtos x Região",
						bullet: "dot",
						page: "relatorio/relatorio04"
					},
				]
			},
			{
				title: "Administradores",
				root: true,
				icon: "fas fa-user-shield",
				page: "admin",
				bullet: "dot"
			},
			{
				title: "Grupos de Acesso",
				root: true,
				icon: "fas fa-layer-group",
				// page: "group",
				bullet: "dot",
				submenu: [
					{
						title: "Administrativo",
						root: true,
						page: "group",
					},
					{
						title: "Embarcador",
						root: true,
						page: "group-embarcador",
					},
					{
						title: "Transportador",
						root: true,
						page: "group-transportador",
					},

				]
			},
		]
	}
};
