let url = "";

if (process.env.NODE_ENV === 'development') {
	url = "http://localhost:8000";
} else if (process.env.REACT_APP_STAGE === 'homolog') {
	url = "https://dev.api.fastfrete.com.br";
} else {
	url = "https://api.v2.fastfrete.com.br";
}

export const Constants = {
	url,
	baseUrl: url + "/api/admin",
	baseUrlCargas: url + "/api/admin/gestao-de-cargas",
	baseUrlPatios: url + "/api/admin/gestao-de-patios",
};
